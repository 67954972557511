<template>
  <v-avatar :size="size" tile>
    <v-img
      v-if="size > 50"
      style="cursor: zoom-in;"
      :src="iconPath"
      @click="$refs.tailorZoomDialog.open()"
      contain
    />
    <v-img v-else :src="iconPath" contain />
    <TailorZoomDialog ref="tailorZoomDialog" :src="iconPath" />
  </v-avatar>
</template>

<script>
import TailorZoomDialog from "@/components/TailorZoomDialog";

export default {
  name: "ItemIcon",
  components: {
    TailorZoomDialog,
  },
  props: {
    defaultIcon: {
      mode: String,
      default: null,
    },
    itemIcon: {
      mode: String,
      default: null,
    },
    //サイズ
    size: {
      mode: Number,
      default: 50,
    },
  },
  data() {
    return {
      imgSize: 0,
      iconId: "",
    };
  },
  watch: {},

  computed: {
    iconPath() {
      if (this.itemIcon === 0 || this.itemIcon == null) {
        if (
          this.defaultIcon !== null &&
          (this.defaultIcon.indexOf("world") === 0 ||
            this.defaultIcon.indexOf("Bullbre") === 0)
        ) {
          return require("@/static/" + this.defaultIcon);
        } else {
          return `${this.objectPath}/images/main/${this.defaultIcon}/${this.defaultIcon}.webP`;
        }
      }

      return `${this.objectPath}/images/main/${this.itemIcon}/${this.itemIcon}.webP`;
    },
  },
  methods: {},
};
</script>
