<template>
  <v-snackbar
    v-model="snack"
    :timeout="6000"
    dark
    color="var(--gray-8)"
    :right="$vuetify.breakpoint.smAndUp"
    content-class="pb-2"
    vertical
    ><div class="d-flex align-center">
      <v-icon :color="snackColor" left>{{ snackIcon }}</v-icon>
      <BaseText :text="msg" />
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn
        v-if="to"
        dark
        text
        right
        v-bind="attrs"
        :color="snackColor"
        :to="to.to"
        >{{ to.label }}</v-btn
      >

      <v-btn
        v-if="closeBtn"
        dark
        text
        right
        v-bind="attrs"
        :color="snackColor"
        @click="snack = false"
        >とじる</v-btn
      >
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  props: {
    type: {
      mode: String,
      default: "",
      // validator: function (value) {
      //   return ["error", "success", "info", "alpaca"].indexOf(value) !== -1;
      // },
    },
    color: {
      mode: String,
      default: "",
    },
    icon: {
      mode: String,
      default: "",
    },
    msg: {
      mode: String,
      required: true,
    },
    closeBtn: {
      mode: Boolean,
      default: true,
    },
    to: {
      mode: Object,
      default: null,
    },
  },
  data() {
    return {
      snack: false,
    };
  },

  computed: {
    snackIcon() {
      if (this.icon) {
        return this.icon;
      } else {
        return this.item.icon;
      }
    },
    snackColor() {
      if (this.color) {
        return this.color;
      } else {
        return this.item.color;
      }
    },
    item() {
      switch (this.type) {
        case "error":
          return {
            icon: "cancel",
            color: "var(--c-error)",
          };
        case "success":
          return {
            icon: "check_circle",
            color: "var(--c-secondary)",
          };
        case "alpaca":
          return {
            icon: "ai-alpaca",
            color: "var(--gray-2)",
          };
        case "info":
        default:
          return {
            icon: "info",
            color: "var(--c-secondary)",
          };
      }
    },
  },

  methods: {
    open() {
      this.snack = true;
    },
  },
};
</script>
