import axios from "axios";
import gv from "../mixins/globalValiables";

export var globalValiables = gv.data;
export default {
  //ギャラリー詳細共通method
  //ギャラリー詳細のクリエイター情報をGET(クリエイター区分込み)

  getCreatorInfo: async function (creatorId, shouhinShubetsu) {
    const res = await axios.get(
      globalValiables().serverPath +
        "/api/Tailor/getSingleCreatorInfo?creatorId=" +
        creatorId +
        "&shouhinShubetsu=" +
        shouhinShubetsu
    );
    //   console.log(res.data)
    return res.data[0];
  },

  //ギャラリー詳細の商品名をGET
  getShouhinmei: async function (shouhinId) {
    const res = await axios.get(
      globalValiables().serverPath +
        "/api/Tailor/getShouhinmei?shouhinId=" +
        shouhinId
    );

    return res.data[0];
  },

  //レディメイドギャラリー詳細の商品名をGET
  getReadyShouhinmei: async function (shouhinId) {
    const res = await axios.get(
      globalValiables().serverPath +
        "/api/Tailor/getShouhinmei?shouhinId=" +
        shouhinId
    );

    return res.data[0];
  },

  //単一クリエイター気に入り共通method
  //ギャラリー詳細のクリエイター気に入り情報をGET
  getCreatorLikedInfo: async function (userId, creatorId) {
    // console.log(userId)
    // console.log(creatorId)

    const res = await axios.get(
      globalValiables().serverPath +
        "/api/Tailor/getSingleCreatorLikedInfo?creatorId=" +
        creatorId +
        "&userId=" +
        userId
    );
    //   console.log(res.data)
    return res.data[0];
  },

  //クリエイターに気になる情報を追加
  addCreatorLike: async function (userId, creatorId) {
    await axios.post(
      globalValiables().serverPath +
        "/api/Tailor/addLikeInfo?userId=" +
        userId +
        "&creatorId=" +
        creatorId
    );
  },

  //クリエイターに気になる情報を削除
  deleteCreatorLike: async function (userId, creatorId) {
    await axios.post(
      globalValiables().serverPath +
        "/api/Tailor/deleteLikeInfo?userId=" +
        userId +
        "&creatorId=" +
        creatorId
    );
  },

  //クリエイターの総評価数を記入する
  updateHyokasu: async function (hyoukasuuCreator, creatorId) {
    await axios.post(
      globalValiables().serverPath +
        "/api/Tailor/updateHyoukasuuCreator?creatorId=" +
        creatorId +
        "&hyoukasuuCreator=" +
        hyoukasuuCreator
    );
  },

  //
};
