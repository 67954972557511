import axios from "axios";
export default {

//date　フォマードの処理(0000年00月00日)
//param:new Date()
dateFormat:function(date){
  var dateTemp = new Date(date);
      var year = dateTemp.getFullYear();
      var month = dateTemp.getMonth() + 1;
      var day = dateTemp.getDate();

      if (day.toString().length === 1) {
        day = "0" + day;
      }
      if (month.toString().length === 1) {
        month = "0" + month;
      }

      return year + "年" + month + "月" + day + "日";
},

//date　フォマードの処理(0000/00/00)
//param:new Date()
dateFormatSimple:function(date){
  var dateTemp = new Date(date);
      var year = dateTemp.getFullYear();
      var month = dateTemp.getMonth() + 1;
      var day = dateTemp.getDate();

      if (day.toString().length === 1) {
        day = "0" + day;
      }
      if (month.toString().length === 1) {
        month = "0" + month;
      }

      return year + "/" + month + "/" + day;
},



//date　フォマードの処理(0000/00/00/ 00:00)
//param:new Date()
dateTimeFormat:function(date){
  var dateTemp = new Date(date);
      var year = dateTemp.getFullYear();
      var month = dateTemp.getMonth() + 1;
      var day = dateTemp.getDate();
      var hours = dateTemp.getHours();
      var minutes = dateTemp.getMinutes();
      

      if (day.toString().length === 1) {
        day = "0" + day;
      }
      if (month.toString().length === 1) {
        month = "0" + month;
      }
      if (hours.toString().length === 1) {
        hours = "0" + hours;
      }
      if (minutes.toString().length === 1) {
        minutes = "0" + minutes;
      }
      

      return year + "/" + month + "/" + day +" " + hours + ":" + minutes;
},






}


