<template>
  <v-app id="AppInspire">
    <template v-if="!loading && isRouterAlive">
      <router-view name="portalHeader" @signOut="signOut" @reload="reload" />
      <v-main v-if="!isWorld" :key="reloadKey">
        <keep-alive
          :max="3"
          include="PortalTop,tailorMain,portalSearchResult,creator"
        >
          <router-view />
        </keep-alive>
      </v-main>
      <keep-alive
        v-else
        :max="3"
        include="PortalTop,tailorMain,portalSearchResult"
      >
        <router-view />
      </keep-alive>
      <router-view name="portalFooter" />
      <Snackbar
        ref="snack"
        :type="snackbarType"
        :msg="snackbarMsg"
        :to="snackbarTo"
      ></Snackbar>
      <!-- 新着メッセージデスクトップで表示 -->
      <!-- <MessageDeskTopNotification v-if="$store.state.characterId"></MessageDeskTopNotification> -->
    </template>
    <UpLoading ref="uploading" />
  </v-app>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import Snackbar from "@/components/Snackbar";
// import MessageDeskTopNotification from "@/components/MessageDeskTopNotification.vue";

export default {
  name: "App",
  components: { Snackbar },

  data() {
    return {
      isRouterAlive: true,
      uploading: false,
      loading: false,
      reloadKey: 0,
      searchKey: 0,
      timer: "",

      snackbarType: "",
      snackbarMsg: "",
      snackbarTo: {},
    };
  },
  provide() {
    return {
      snack: this.snack,
      reload: this.reload,
      searchReload: this.searchReload,
      upload: this.upload,
      getLikedInfo: this.getLikedInfo,
      clipboardCopy: this.clipboardCopy,
    };
  },

  watch: {
    "$store.state.userId": {
      handler: function (val) {
        if (val) {
          this.getLikedInfo();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    isWorld() {
      return this.$route.path.indexOf("/world/") === 0;
    },
  },
  async created() {
    this.loading = true;
    const user = await new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => resolve(user));
    });

    if (user) {
      const idToken = await user.getIdToken();
      if (
        this.$store.state.userId != user.uid ||
        this.$store.state.userIdToken != idToken
      ) {
        this.$store.dispatch("loginUser", {
          userId: user.uid,
          userIdToken: idToken,
        });
      }

      this.$store.dispatch("mailAddress", {
        mailAddress: user.email,
      });
      axios
        .post(this.serverPath + "/api/user/syncMailaddress", {
          userId: user.uid,
          mailaddress: user.email,
        })
        .then(async (response) => {});
    } else {
      this.$store.dispatch("logoutUser");
      // this.$router.push("/login");
    }
    const tokenUser = await new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((tokenUser) => resolve(tokenUser));
    });
    if (tokenUser) {
      const idToken = await tokenUser.getIdToken();
      if (
        this.$store.state.userId != tokenUser.uid ||
        this.$store.state.userIdToken != idToken
      ) {
        this.$store.dispatch("loginUser", {
          userId: tokenUser.uid,
          userIdToken: idToken,
        });
        //this.$router.go({path: this.$router.currentRoute.path, force: true});
      }
    } else {
      this.$store.dispatch("logoutUser");
      // this.$router.push("/login");
    }

    if (window.sessionStorage.sessionId) {
      window.sessionId = window.sessionStorage.sessionId;
      window.sessionStorage.removeItem("sessionId");
    } else {
      try {
        window.sessionId = Math.floor(Math.random() * 1000000).toString();
        if (this.$store.state.userId) {
          const resClass = await axios.get(this.serverPath + "/api/userClass", {
            params: {
              userId: this.$store.state.userId,
            },
          });
          this.updateStore(resClass);
        }
      } catch (error) {
        //alert(error);
      }
    }
    //this.timer = setInterval(this.getToken, 1800000);
    // 10分ごとにfirebaseトークンチェック
    this.timer = setInterval(this.getToken, 600000);

    window.addEventListener("beforeunload", this.beforeWindowUnload);
    this.loading = false;
  },

  methods: {
    //ユーザーのお気に入りリストを取得してstoreに保存する
    async getLikedInfo() {
      const userId = this.$store.state.userId;
      if (userId) {
        const reviewRes = await axios.get(
          this.serverPath + "/api/Tailor/getLikeInfo?userId=" + userId
        );
        this.$store.dispatch("cLikedList", { cLikedList: reviewRes.data });
      }
    },

    getToken: async function () {
      const user = await new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged((user) => resolve(user));
      });

      if (user) {
        const idToken = await user.getIdToken();

        if (
          this.$store.state.userId != user.uid ||
          this.$store.state.userIdToken != idToken
        ) {
          this.$store.dispatch("loginUser", {
            userId: user.uid,
            userIdToken: idToken,
          });
        }
        //メールアドレスをdispatch
        this.$store.dispatch("mailAddress", {
          mailAddress: user.email,
        });

        axios
          .post(this.serverPath + "/api/user/syncMailaddress", {
            userId: user.uid,
            mailaddress: user.email,
          })
          .then(async (response) => {});
      } else {
        this.$store.dispatch("logoutUser");
      }
      const tokenUser = await new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged((tokenUser) => resolve(tokenUser));
      });
      if (tokenUser) {
        const idToken = await tokenUser.getIdToken();
        if (
          this.$store.state.userId != tokenUser.uid ||
          this.$store.state.userIdToken != idToken
        ) {
          this.$store.dispatch("loginUser", {
            userId: tokenUser.uid,
            userIdToken: idToken,
          });
        }
      } else {
        this.$store.dispatch("logoutUser");
      }

      if (window.sessionStorage.sessionId) {
        window.sessionId = window.sessionStorage.sessionId;
        window.sessionStorage.removeItem("sessionId");
      } else {
        try {
          window.sessionId = Math.floor(Math.random() * 1000000).toString();
          if (this.$store.state.userId) {
            const resClass = await axios.get(
              this.serverPath + "/api/userClass",
              {
                params: {
                  userId: this.$store.state.userId,
                },
              }
            );
            this.updateStore(resClass);
          }
        } catch (error) {
          //alert(error);
        }
      }

      window.addEventListener("beforeunload", this.beforeWindowUnload);
    },
    signOut: async function () {
      this.$refs.uploading.uploading = true;
      await firebase.auth().signOut();
      await this.$store.commit("logoutUser");

      setTimeout(this.gotoTop, 1000);
    },
    gotoTop() {
      this.$router.push({ path: "/login" });
      this.$refs.uploading.uploading = false;
    },
    upload(val) {
      this.$refs.uploading.uploading = val;
    },
    reload() {
      this.reloadKey++;
    },
    searchReload() {
      this.searchKey++;
    },

    //iOSの判定
    isIOS() {
      const agent = window.navigator.userAgent;
      return agent.indexOf("iPhone") != -1 || agent.indexOf("iPad") != -1;
    },

    //クリップボードにコピー
    clipboardCopy(clipValue, label) {
      navigator.clipboard.writeText(clipValue).then(
        (success) => this.clipboardCopyReject(true, label),
        (error) => this.clipboardCopyReject(false, label)
      );
    },

    clipboardCopyReject(reject, label) {
      if (reject) {
        this.snack("success", label + "をクリップボードにコピーしました");
      } else {
        this.snack("error", label + "コピーに失敗しました");
      }
    },

    // スナックバーを表示
    snack(type = "success", msg = "成功しました", to = null) {
      this.snackbarType = type;
      this.snackbarMsg = msg;
      this.snackbarTo = to;

      setTimeout(() => {
        this.$refs.snack.open();
      }, 100);
    },

    beforeWindowUnload(e) {
      window.sessionStorage.sessionId = window.sessionId;
    },
    clearStore() {
      this.$store.dispatch("adminFlag", {
        adminFlag: "0",
      });
      this.$store.dispatch("playerFlag", {
        playerFlag: "0",
      });
      this.$store.dispatch("masterFlag", {
        masterFlag: "0",
      });
      this.$store.dispatch("creatorFlag", {
        creatorFlag: "0",
      });
      this.$store.dispatch("creatorId", {
        creatorId: "",
      });
      this.$store.dispatch("userClassName", {
        userClassName: "",
      });
      this.$store.dispatch("userClassIcon", {
        userClassIcon: "",
      });
      this.$store.dispatch("masterClassName", {
        masterClassName: "",
      });
      this.$store.dispatch("masterClassIcon", {
        masterClassIcon: "",
      });
      this.$store.dispatch("masterId", {
        creatorId: "",
      });
      this.$store.dispatch("illustClassName", {
        illustClassName: "",
      });
      this.$store.dispatch("illustClassIcon", {
        illustClassIcon: "",
      });
      this.$store.dispatch("illustClassId", {
        illustClassId: "",
      });
      this.$store.dispatch("voiceClassName", {
        voiceClassName: "",
      });
      this.$store.dispatch("voiceClassIcon", {
        voiceClassIcon: "",
      });
      this.$store.dispatch("voiceClassId", {
        voiceClassId: "",
      });
      this.$store.dispatch("writerClassName", {
        writerClassName: "",
      });
      this.$store.dispatch("writerClassIcon", {
        writerClassIcon: "",
      });
      this.$store.dispatch("writerClassId", {
        writerClassId: "",
      });
      this.$store.dispatch("adminClassName", {
        adminClassName: "",
      });
      this.$store.dispatch("adminClassIcon", {
        adminClassIcon: "",
      });
    },
    updateStore(resClass) {
      try {
        this.clearStore();

        if (resClass.data.userInfo) {
          this.$store.dispatch("userClassName", {
            userClassName: resClass.data.userInfo.userName,
          });
          this.$store.dispatch("userClassIcon", {
            userClassIcon: resClass.data.userInfo.icon,
          });
        }
        if (resClass.data.masterInfo) {
          this.$store.dispatch("masterId", {
            masterId: resClass.data.masterInfo.masterId,
          });
          this.$store.dispatch("masterClassName", {
            masterClassName: resClass.data.masterInfo.masterName,
          });
          this.$store.dispatch("masterClassIcon", {
            masterClassIcon: resClass.data.masterInfo.icon,
          });
        }
        if (resClass.data.creatorInfoIllust) {
          this.$store.dispatch("illustClassName", {
            illustClassName: resClass.data.creatorInfoIllust.creatorName,
          });
          this.$store.dispatch("illustClassIcon", {
            illustClassIcon: resClass.data.creatorInfoIllust.icon,
          });
          this.$store.dispatch("illustClassId", {
            illustClassId: resClass.data.creatorInfoIllust.creatorId,
          });
        }
        if (resClass.data.creatorInfoVoice) {
          this.$store.dispatch("voiceClassName", {
            voiceClassName: resClass.data.creatorInfoVoice.creatorName,
          });
          this.$store.dispatch("voiceClassIcon", {
            voiceClassIcon: resClass.data.creatorInfoVoice.icon,
          });
          this.$store.dispatch("voiceClassId", {
            voiceClassId: resClass.data.creatorInfoVoice.creatorId,
          });
        }
        if (resClass.data.creatorInfoWriter) {
          this.$store.dispatch("writerClassName", {
            writerClassName: resClass.data.creatorInfoWriter.creatorName,
          });
          this.$store.dispatch("writerClassIcon", {
            writerClassIcon: resClass.data.creatorInfoWriter.icon,
          });
          this.$store.dispatch("writerClassId", {
            writerClassId: resClass.data.creatorInfoWriter.creatorId,
          });
        }
        if (resClass.data.userInfoAdmin) {
          this.$store.dispatch("adminClassName", {
            adminClassName: resClass.data.userInfoAdmin.userName,
          });
          this.$store.dispatch("adminClassIcon", {
            adminClassIcon: resClass.data.userInfoAdmin.icon,
          });
        }

        var ret = true;

        switch (this.$store.state.lastUsedClass) {
          case 0: //Player
            this.$store.dispatch("userName", {
              userName: this.$store.state.userClassName,
            });
            this.$store.dispatch("userIcon", {
              userIcon: this.$store.state.userClassIcon,
            });
            this.$store.dispatch("adminFlag", {
              adminFlag: "0",
            });
            this.$store.dispatch("playerFlag", {
              playerFlag: "1",
            });
            this.$store.dispatch("masterFlag", {
              masterFlag: "0",
            });
            this.$store.dispatch("creatorFlag", {
              creatorFlag: "0",
            });
            this.$store.dispatch("creatorId", {
              creatorId: "",
            });
            break;
          case 1: //Master
            if (this.$store.state.masterClassName != "") {
              this.$store.dispatch("userName", {
                userName: this.$store.state.masterClassName,
              });
            } else {
              ret = false;
              break;
            }
            if (this.$store.state.masterClassIcon != "") {
              this.$store.dispatch("userIcon", {
                userIcon: this.$store.state.masterClassIcon,
              });
            }
            this.$store.dispatch("adminFlag", {
              adminFlag: "0",
            });
            this.$store.dispatch("playerFlag", {
              playerFlag: "0",
            });
            this.$store.dispatch("masterFlag", {
              masterFlag: "1",
            });
            this.$store.dispatch("creatorFlag", {
              creatorFlag: "0",
            });
            this.$store.dispatch("creatorId", {
              creatorId: "",
            });
            break;
          case 2: //Illustrator
            if (this.$store.state.illustClassName != "") {
              this.$store.dispatch("userName", {
                userName: this.$store.state.illustClassName,
              });
            } else {
              ret = false;
              break;
            }
            if (this.$store.state.illustClassIcon != "") {
              this.$store.dispatch("userIcon", {
                userIcon: this.$store.state.illustClassIcon,
              });
            }
            this.$store.dispatch("adminFlag", {
              adminFlag: "0",
            });
            this.$store.dispatch("playerFlag", {
              playerFlag: "0",
            });
            this.$store.dispatch("masterFlag", {
              masterFlag: "0",
            });
            this.$store.dispatch("creatorFlag", {
              creatorFlag: "1",
            });
            this.$store.dispatch("creatorId", {
              creatorId: resClass.data.creatorInfoIllust.creatorId,
            });
            break;
          case 3: //Voice
            if (this.$store.state.voiceClassName) {
              this.$store.dispatch("userName", {
                userName: this.$store.state.voiceClassName,
              });
            } else {
              ret = false;
              break;
            }
            if (this.$store.state.voiceClassIcon) {
              this.$store.dispatch("userIcon", {
                userIcon: this.$store.state.voiceClassIcon,
              });
            }
            this.$store.dispatch("adminFlag", {
              adminFlag: "0",
            });
            this.$store.dispatch("playerFlag", {
              playerFlag: "0",
            });
            this.$store.dispatch("masterFlag", {
              masterFlag: "0",
            });
            this.$store.dispatch("creatorFlag", {
              creatorFlag: "2",
            });
            this.$store.dispatch("creatorId", {
              creatorId: resClass.data.creatorInfoVoice.creatorId,
            });
            break;
          case 4: //Writer
            if (this.$store.state.writerClassName != "") {
              this.$store.dispatch("userName", {
                userName: this.$store.state.writerClassName,
              });
            } else {
              ret = false;
              break;
            }
            if (this.$store.state.writerClassIcon != "") {
              this.$store.dispatch("userIcon", {
                userIcon: this.$store.state.writerClassIcon,
              });
            }
            this.$store.dispatch("adminFlag", {
              adminFlag: "0",
            });
            this.$store.dispatch("playerFlag", {
              playerFlag: "0",
            });
            this.$store.dispatch("masterFlag", {
              masterFlag: "0",
            });
            this.$store.dispatch("creatorFlag", {
              creatorFlag: "3",
            });
            this.$store.dispatch("creatorId", {
              creatorId: resClass.data.creatorInfoWriter.creatorId,
            });
            break;
          case 5: //Admin
            if (this.$store.state.adminClassName != "") {
              this.$store.dispatch("userName", {
                userName: this.$store.state.adminClassName,
              });
            } else {
              ret = false;
              break;
            }
            if (this.$store.state.adminClassIcon != "") {
              this.$store.dispatch("userIcon", {
                userIcon: this.$store.state.adminClassIcon,
              });
            }
            this.$store.dispatch("adminFlag", {
              adminFlag: "1",
            });
            this.$store.dispatch("playerFlag", {
              playerFlag: "0",
            });
            this.$store.dispatch("masterFlag", {
              masterFlag: "0",
            });
            this.$store.dispatch("creatorFlag", {
              creatorFlag: "0",
            });
            this.$store.dispatch("creatorId", {
              creatorId: "",
            });
            break;
          default:
            //userClass未選択
            this.$store.dispatch("userName", {
              userName: this.$store.state.userClassName,
            });
            this.$store.dispatch("userIcon", {
              userIcon: null,
            });
            this.$store.dispatch("lastUsedClass", {
              lastUsedClass: null,
            });
            this.$store.dispatch("adminFlag", {
              adminFlag: "0",
            });
            this.$store.dispatch("playerFlag", {
              playerFlag: "0",
            });
            this.$store.dispatch("masterFlag", {
              masterFlag: "0",
            });
            this.$store.dispatch("creatorFlag", {
              creatorFlag: "0",
            });
            this.$store.dispatch("creatorId", {
              creatorId: "",
            });
            break;
        }

        // 問題が発生される場合デフォルトに設定する
        if (ret != true) {
          this.$store.dispatch("userName", {
            userName: this.$store.state.userClassName,
          });
          this.$store.dispatch("userIcon", {
            userIcon: this.$store.state.userClassIcon,
          });
          this.$store.dispatch("lastUsedClass", {
            lastUsedClass: null,
          });
          this.$store.dispatch("adminFlag", {
            adminFlag: "0",
          });
          this.$store.dispatch("playerFlag", {
            playerFlag: "0",
          });
          this.$store.dispatch("masterFlag", {
            masterFlag: "0",
          });
          this.$store.dispatch("creatorFlag", {
            creatorFlag: "0",
          });
          this.$store.dispatch("creatorId", {
            creatorId: "",
          });
        }
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/portal.scss";
@import "@/sass/world.scss";

.v-main__wrap {
  display: flex;
  flex-direction: column;
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>
