<template>
  <div class="socialAuthButton" id="firebaseui-auth-container"></div>
</template>

<script>
import * as firebaseui from "firebaseui";
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";

export default {
  name: "socialLogin",
  data() {
    return {
      userId: "",
      password: "",
      mailaddress: "",
      captchaToken: "",
      ui: "",
      showSocialButton: true,
      logType: "signup",
      idToken: "",
      rediectUrl: "",
      idToken: "",
      test: ""
    };
  },

  created() {
    // //毎回入る時刷新（firebaseuiを起動ため）
    // if (localStorage.getItem("reloaded")) {
    //   // The page was just reloaded. Clear the value from local storage
    //   // so that it will reload the next time this page is visited.
    //   localStorage.removeItem("reloaded");
    // } else {
    //   // Set a flag so that we know not to reload the page twice.
    //   localStorage.setItem("reloaded", "1");
    //   this.$router.go(0);
    // }

    //認証APIをロード
    this.signInOAuth();
  },
  mounted() {
    this.signInOAuth();
  },

  methods: {
    //APIで直接ログイン機能
    signInOAuth: function() {
      //firebaseUIで起動
      this.ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth());

      this.ui.start("#firebaseui-auth-container", {
        //必要な部品を書き込む
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.TwitterAuthProvider.PROVIDER_ID
        ],
        // immediateFederatedRedirect:true,
        signInFlow: "popup",

        //リダイレクトURL
        // signInSuccessUrl: "/portalTop",
        signInSuccessUrl: "/loading",

        callbacks: {
          signInSuccessWithAuthResult: async function() {
            // var idToken = firebase.auth().currentUser.uid;

            // //BDに接続、userId検査条件で自分のuserIdを獲得（ユーザ存在チェック）
            // const res = await axios({
            //   method: "get",
            //   url: this.serverPath + "/api/user/selectId?userId=" + idToken
            // });
            // if (res.data === "") {
            //   window.location.pathname = "/info";
            //   // this.$store.dispatch('userId', {userId: idToken})
            // } else {
            //   window.location.pathname = "/loading";
            //   // this.$store.dispatch('userId', {userId: idToken})
            // }
            // return false;
            return true;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
div {
  margin: auto;
  margin-top: 20px;
}
.signupContainer {
  background-color: #f2ece7;
}
.signup {
  width: 40%;
  margin: auto;
}
.firstTitle {
  font-size: 50px;
  font-weight: 900;
  color: #5a4d48;
}
.secondTitle {
  font-size: 15px;
  font-weight: 700;
  color: #5a4d48;
}
.inputArea {
  width: 70%;
}
input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: white;
  border: 1px lightgray solid;
}
.textCenter {
  text-align: center;
}
.googleButton {
  margin-right: 5px;
  color: white;
}
.twitterButton {
  margin-left: 5px;
}
.socialBtnArea {
  width: 70%;
  margin-bottom: 50px;
}
.textFont {
  font-size: 10;
  font-weight: 300;
  color: #5a4d48;
}
.signupButton {
  font-size: 20px !important;
  font-weight: 600;
  color: white !important;
}
/* .socialAuthButton{
border: #5a4d48 solid 1px;
  background-color:#f25d5d;
  width: 50px;
  height: 10px;
} */
.socialAuthButton /deep/ ul {
  list-style-type: none !important;
}

.socialAuthButton /deep/ li {
  display: inline;
  margin-right: 10px;
  margin-left: 10px;
  list-style-type: none !important;
}

.socialAuthButton /deep/ .firebaseui-idp-text-long {
  display: none;
}
.socialAuthButton /deep/ .firebaseui-idp-text {
  margin: 10px;
  font-size: 20px;
  font-weight: 600;
}

.socialAuthButton /deep/ .firebaseui-idp-icon {
  width: 30px;
  height: 30px;
  margin: 10px;
}
.socialAuthButton /deep/ .firebaseui-list-item {
  border-radius: 25px;
}
.socialAuthButton /deep/ .firebaseui-idp-google {
  /* background-image: url("~@/assets/icon/pixiv_icon.png"); */
  color: white;
  background: #da4f46 !important;
  border-radius: 10px;
}
/* .socialAuthButton /deep/ .firebaseui-idp-google .firebaseui-idp-icon {
  background-image: url("~@/assets/icon/pixiv_icon.png");
  src:unset !important;
} */
.socialAuthButton /deep/ .firebaseui-idp-twitter {
  color: white;
  border-radius: 10px;
}
</style>
