var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userId
    ? _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "div",
                      _vm._g({}, on),
                      [
                        _c(
                          "v-fab-transition",
                          [
                            _vm.isSelected
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "fs-xs font-weight-bold",
                                    attrs: {
                                      loading: _vm.loading,
                                      fab: "",
                                      text: "",
                                      height: "40",
                                      width: "40",
                                      outlined: "",
                                      color: "var(--gray-4)",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.clickCall($event)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "loader",
                                          fn: function () {
                                            return [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  size: 16,
                                                  width: 1,
                                                  indeterminate: "",
                                                  color: "primary",
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("v-icon", [_vm._v("email")])],
                                  1
                                )
                              : _c(
                                  "v-btn",
                                  {
                                    staticClass: "fs-xs font-weight-bold",
                                    attrs: {
                                      depressed: "",
                                      dark: _vm.ableToAddCallList,
                                      loading: _vm.loading,
                                      fab: "",
                                      height: "40",
                                      width: "40",
                                      outlined: !_vm.ableToAddCallList,
                                      disabled: !_vm.ableToAddCallList,
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.clickCall($event)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "loader",
                                          fn: function () {
                                            return [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  size: 16,
                                                  width: 1,
                                                  indeterminate: "",
                                                  color: "white",
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("v-icon", [_vm._v("email")])],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            2837876608
          ),
        },
        [
          _c("span", [_vm._v("コール")]),
          _vm.isSelected
            ? _c("span", [_vm._v("解除")])
            : _c("span", [
                _vm._v("登録"),
                !_vm.ableToAddCallList
                  ? _c("span", [_vm._v("上限に達しました")])
                  : _vm._e(),
              ]),
        ]
      )
    : _c(
        "div",
        [
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                height: "20px",
                width: "98px",
                outlined: "",
                disabled: "",
              },
            },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { "x-small": "" } }, [
                _vm._v("email"),
              ]),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }