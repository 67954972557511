<template>
  <div class="sw-Loading">
    <div class="sw-Loading_Box">
      <div class="sw-Loading_Inner">
        <!-- <v-progress-linear
        class="sw-Loading_Linear"
        :color="ColorTextSub"
        dark
        indeterminate
        rounded
        height="6"
        ></v-progress-linear>-->
        <v-progress-circular indeterminate :color="color"></v-progress-circular>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      mode: String,
      default: "var(--gray-6)",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/import.scss";
.sw-Loading {
  position: relative;
  width: 100%;
  min-height: 80px;
  &_Box {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: space-around;
    width: 100%;

    transform: translateY(-60%) translateX(-50%);
  }
  // &_Inner {
  //   flex-basis: 280px;
  // }
  &_Msg {
    padding-bottom: 8px;
    padding-left: 4px;
    font-size: $fs-xs;
    text-align: center;
    letter-spacing: 4px;
  }
}
</style>
