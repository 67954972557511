var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "AppInspire" } },
    [
      !_vm.loading && _vm.isRouterAlive
        ? [
            _c("router-view", {
              attrs: { name: "portalHeader" },
              on: { signOut: _vm.signOut, reload: _vm.reload },
            }),
            !_vm.isWorld
              ? _c(
                  "v-main",
                  { key: _vm.reloadKey },
                  [
                    _c(
                      "keep-alive",
                      {
                        attrs: {
                          max: 3,
                          include:
                            "PortalTop,tailorMain,portalSearchResult,creator",
                        },
                      },
                      [_c("router-view")],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "keep-alive",
                  {
                    attrs: {
                      max: 3,
                      include: "PortalTop,tailorMain,portalSearchResult",
                    },
                  },
                  [_c("router-view")],
                  1
                ),
            _c("router-view", { attrs: { name: "portalFooter" } }),
            _c("Snackbar", {
              ref: "snack",
              attrs: {
                type: _vm.snackbarType,
                msg: _vm.snackbarMsg,
                to: _vm.snackbarTo,
              },
            }),
          ]
        : _vm._e(),
      _c("UpLoading", { ref: "uploading" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }