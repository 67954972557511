<template>
  <v-dialog
    v-model="dialog"
    class="sw-Dialog sw-Dialog-alert"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-title
        class="sw-Dialog_Title subtitle-1 font-weight-bold mb-4"
        style="background-color: var(--gray-2);"
      >
        <v-icon v-if="icon != null" class="pr-2">{{ icon }}</v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text class="sw-Dialog_Text">
        <slot><span v-html="text"></span></slot>
      </v-card-text>
      <v-card-actions class="sw-Dialog_BtnAre">
        <v-spacer></v-spacer>
        <v-btn
          text
          class="sw-Dialog_Btn"
          :color="ColorPrimary"
          @click="discard"
          >{{ btnName }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CommonDialog",

  /*
      icon:       アイコン
      title:      タイトル
      text:       メッセージ内容
      btnName:    ボタン名称
    */
  props: {
    icon: { status: String, default: () => "error" },
    title: String,
    text: String,
    btnName: { status: String, default: () => "とじる" },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    discard() {
      this.dialog = false;
      this.$emit("dialogReturn");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/import.scss";
.sw-Alert {
  &_H2 {
    padding-bottom: 2px;
    margin-bottom: 8px;
    font-weight: bold;
    border-bottom: solid 1px var(--gray-3);
  }
}
</style>
