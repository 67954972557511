<template>
  <!-- 拡大ダイアログ -->
  <v-dialog
    scrollable
    v-model="dialog"
    overlay-opacity="1"
    fullscreen
    transition="fade-transition"
  >
    <v-card
      class="gDetail-IllPreview px-0 d-flex justify-center align-center"
      @click.native="close"
      v-resize="onResize"
    >
      <div class="gDetail-IllPreview_BtnArea d-flex flex-column">
        <v-btn fab elevation="0" small class="">
          <v-icon>close</v-icon>
        </v-btn>

        <v-btn
          v-if="isBigImg"
          @click.stop="clickFit"
          fab
          elevation="0"
          small
          class="mt-3"
        >
          <v-icon>{{ fitScreen ? "zoom_out_map" : "fit_screen" }}</v-icon>
        </v-btn>
      </div>
      <div class="gDetail-IllPreview_Wrap">
        <div class="gDetail-IllPreview_Box">
          <img
            v-if="dialog"
            class="gDetail-IllPreview_Img ma-auto"
            ref="target"
            height="auto"
            width="auto"
            :style="fitScreen ? imgStyle : ''"
            :src="src"
            contain
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// import DOMPurify from "dompurify";

export default {
  name: "TailorZoomDialog",
  props: {
    src: {
      mode: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      fitScreen: false,
      imgStyle: "",
      windowSize: {
        width: 0,
        height: 0,
      },

      imgSize: { width: 999999, height: 999999 },
    };
  },
  computed: {
    isBigImg() {
      if (
        this.imgSize.width > this.windowSize.width ||
        this.imgSize.height > this.windowSize.height
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.onResize();
  },
  watch: {
    async src() {
      var i = new Image(); //ここからサイズチェック
      i.onload = function () {
        _this.imgSize = { width: i.width, height: i.height };
      };
      const _this = this;
      i.src = this.src;
    },
  },
  methods: {
    clickFit() {
      this.fitScreen = !this.fitScreen;
      this.$vuetify.goTo(0, { duration: 0 });
    },

    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.fitScreen = false;
    },
    onResize() {
      this.windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      this.imgStyle = `max-width:${window.innerWidth}px; max-height:${window.innerHeight}px;`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/import.scss";
.gDetail {
  &-IllPreview {
    &_BtnArea {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 4;
    }
    &_Wrap {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    &_Box {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      display: flex;
      flex-flow: column;
      min-height: 100%;
      overflow: auto;
      cursor: zoom-out;
      background-color: rgb(255, 255, 255);
    }
  }
}
</style>
