<template>
  <v-tooltip top v-if="userId">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-fab-transition>
          <v-btn
            v-if="isSelected"
            :loading="loading"
            @click.prevent="clickCall"
            class="fs-xs font-weight-bold"
            fab
            text
            height="40"
            width="40"
            outlined
            color="var(--gray-4)"
          >
            <v-icon>email</v-icon>
            <template v-slot:loader>
              <v-progress-circular
                :size="16"
                :width="1"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </template>
          </v-btn>
          <v-btn
            v-else
            depressed
            :dark="ableToAddCallList"
            :loading="loading"
            @click.prevent="clickCall"
            fab
            height="40"
            width="40"
            :outlined="!ableToAddCallList"
            :disabled="!ableToAddCallList"
            color="primary"
            class="fs-xs font-weight-bold"
          >
            <v-icon>email</v-icon>
            <template v-slot:loader>
              <v-progress-circular
                :size="16"
                :width="1"
                indeterminate
                color="white"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-fab-transition>
      </div>
    </template>
    <span>コール</span> <span v-if="isSelected">解除</span
    ><span v-else
      >登録<span v-if="!ableToAddCallList">上限に達しました</span></span
    >
  </v-tooltip>
  <div v-else>
    <v-btn text height="20px" width="98px" outlined disabled>
      <v-icon x-small class="mr-1">email</v-icon>
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "MasterCallBtn",
  components: {},
  props: {
    maxCount: {
      mode: Number,
      required: true,
    },
    masterId: {
      mode: String,
      required: true,
    },
    masterName: {
      mode: String,
      required: true,
    },
    masterCallMasterList: {
      mode: Object,
      required: true,
    },
    displayMode: {
      mode: String,
    },
    size: {
      mode: String,
      default: "s",
    },
  },
  inject: ["snack"],
  data() {
    return {
      userId: this.$store.state.userId,
      loading: false,
      liftedFlg: false,
    };
  },
  created() {},

  computed: {
    ableToAddCallList() {
      if (this.masterCallMasterList) {
        return this.masterCallMasterList.length < this.maxCount;
      } else {
        return true;
      }
    },
    isSelected() {
      if (this.liftedFlg) {
        return false;
      }

      if (this.masterCallMasterList != []) {
        const i = this.masterCallMasterList.find(
          (val) => val.masterId === this.masterId
        );
        if (i) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    async clickCall() {
      this.loading = true;

      const data = {
        masterId: this.masterId,
        userId: this.userId,
        priority: 999,
      };
      let res = null;
      let type = "";
      try {
        if (this.isSelected) {
          res = await axios.post(
            this.serverPath + "/api/MasterCall/delete",
            data
          );
          type = "delete";
          this.liftedFlg = true;
          this.snack(
            "success",
            this.masterName + "さんのマスターコールを解除しました。"
          );
        } else {
          res = await axios.post(
            this.serverPath + "/api/MasterCall/create",
            data
          );
          type = "create";
          this.liftedFlg = false;
          this.snack(
            "success",
            this.masterName +
              "さんをマスターコールに登録しました。\n受注開始時にメールが送信されます。"
          );
        }
      } catch (e) {
        this.snack(
          "error",
          "マスターコール登録に失敗しました。\n登録数上限をご確認ください。"
        );
      } finally {
        const data = {
          masterId: this.masterId,
          type: type,
        };
        this.$emit("updateMasterCallList", data);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/import.scss";

.swHelp {
  position: relative;
  color: var(--gray-8);
  background: var(--white);
  border-top: solid 1px var(--c-border);
  border-right: solid 1px var(--c-border);
  border-bottom: solid 1px var(--c-border);
  border-left: solid 4px var(--c-secondary);
  @include textBox;
}
</style>
