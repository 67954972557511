import { render, staticRenderFns } from "./ItemIcon.vue?vue&type=template&id=2149945c"
import script from "./ItemIcon.vue?vue&type=script&lang=js"
export * from "./ItemIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2149945c')) {
      api.createRecord('2149945c', component.options)
    } else {
      api.reload('2149945c', component.options)
    }
    module.hot.accept("./ItemIcon.vue?vue&type=template&id=2149945c", function () {
      api.rerender('2149945c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ItemIcon.vue"
export default component.exports