var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-avatar",
    { attrs: { size: _vm.size, tile: "" } },
    [
      _vm.size > 50
        ? _c("v-img", {
            staticStyle: { cursor: "zoom-in" },
            attrs: { src: _vm.iconPath, contain: "" },
            on: {
              click: function ($event) {
                return _vm.$refs.tailorZoomDialog.open()
              },
            },
          })
        : _c("v-img", { attrs: { src: _vm.iconPath, contain: "" } }),
      _c("TailorZoomDialog", {
        ref: "tailorZoomDialog",
        attrs: { src: _vm.iconPath },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }